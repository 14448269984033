.hi-logo {
  a {
    color: @off-white;
  }
}

.hi-trigger {
  cursor: pointer;
  display: none;

  &:before {
    background: @card-bg;
  }
}

.hi-menu {
  & > li {
    & > a {
      color: @off-white;

      & > .him-counts {
        background: #C13228;
      }
    }

    &.toggled > a,
    &:hover > a {
      background-color: rgba(255, 255, 255, 0.04);
    }
  }
}

#header {
  background-color: @header-bg;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);

  .hi-logo a {
    padding: 7px 10px;
  }

  .hi-trigger {
    @media (max-width: (@screen-md-max + 80)) {
      display: inline-block;
    }
  }
}

.h-search-wrap {
  background: @header-bg;

  input[type="text"] {
    background-color: #fff;
    color: @text-dark;
  }

  .hsw-close {
    color: @header-bg;

    &:hover {
      background-color: #eee;
    }
  }
}

#header-alt {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: @header-bg;

  @media (min-width: @screen-md-min) {
    &:before {
      background: rgba(0, 0, 0, 0.08);
    }
  }

  .hi-trigger {

    @media (max-width: @screen-sm-max) {
      display: inline-block;
    }
  }

  .ha-search {
    input[type="text"] {
      border-bottom: 1px solid @input-border;
      .placeholder(@off-white);

      &.ie9-placeholder {
        color: @off-white !important;
      }
    }
  }
}

.ha-menu {
  @media (min-width: @screen-md-min) {
    & > ul {
      & > li {
        &:not(.active) > a {
          color: rgba(255, 255, 255, 0.3);
        }

        &.active > a {
          color: @off-white;
          box-shadow: inset 0px -2px 0 0px @off-white;
        }
      }
    }
  }
}

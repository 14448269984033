.list-group {
  &.lg-odd-white {
    .list-group-item:nth-child(odd) {
      background-color: @list-group-hover-bg;
    }
  }

  &.lg-even-white {
    .list-group-item:nth-child(even) {
      background-color: @list-group-hover-bg;
    }
  }
}

.lgi-heading {
  color: @off-white;
}

.lgi-text {
  color: rgba(255, 255, 255, 0.3);
}

.list-group-light {
  .list-group-item {
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .lgi-heading {
    color: #000;
  }

  .lgi-text {
    color: #A9A9A9;
  }
}

.sidebar {
  background: @card-bg;
  height: ~"calc(100% - 65px)";
}

#sidebar {
  @media (max-width: (@screen-md-max + 80)) {
    .translate3d(-@sidebar-left-width, 0, 0);
    box-shadow: 0 0 30px #0E1215;
  }
}

.main-menu {
  a:hover,
  .active > a,
  a.active {
    color: #fff;
  }

  & > li {
    & > a {
      color: rgba(255, 255, 255, 0.65);
    }

    & > a:hover,
    &.active > a {
      background-color: rgba(255, 255, 255, 0.04);
    }
  }
}

.sub-menu {
  a {
    color: rgba(255, 255, 255, 0.4);
  }

  & > a {
    &:before, &:after {
      .backface-visibility(hidden);
      .opacity(0);
    }

    &:hover {
      &:before,
      &:after {
        .opacity(0.7);
      }
    }
  }

  &.toggled,
  &.active {
    & > a {
      &:before,
      &:after {
        .opacity(0.7);
      }
    }
  }

  ul {
    & > li {
      ul {
        background-color: rgba(255, 255, 255, 0.03);
      }
    }
  }
}
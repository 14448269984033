.bootgrid-header {
  .actions {
    .btn {
      color: @off-white;
    }

    .btn-group {
      .dropdown-menu {
        .dropdown-item {
          color: #747474;
        }
      }
    }
  }

  .checkbox {
    color: @text-dark;
  }
}

.bootgrid-table th > .column-header-anchor {
  color: @off-white;
}

.bootgrid-table th:active, .bootgrid-table th:hover {
  background-color: rgba(255, 255, 255, 0.1);
}


.command-edit, .command-delete {
  background: rgba(255, 255, 255, 0.1);
}

.bootgrid-table td.loading, .bootgrid-table td.no-results {
  background-color: @card-bg;
}

.btn-link {
  color: @text-color;

  &:hover {
    color: #fff;
  }
}

.btn-float {
  &:not(.bgm-white):not(.bgm-gray):not(.btn-default) {
    & > i {
      color: #fff;
    }
  }
}
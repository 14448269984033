#profile-main {
  .pm-overview {
    background: rgba(255, 255, 255, 0.04);
    overflow-y: auto;

    @media (max-width: @screen-xs-max) {
      margin-bottom: 20px;
    }
  }

  .pmo-pic {
    .pmo-stat {
      @media (min-width: @screen-sm-min) {
        background: @off-white;
        color: @text-dark;

        h2 {
          color: @text-dark;
        }
      }
    }
  }

  .pmb-block {
    &.toggled {
      .pmbb-edit {
        background: rgba(255, 255, 255, 0.04);
        padding: 23px 30px;
      }
    }
  }
}

.p-menu {
  & > li {
    & > a {
      color: rgba(255, 255, 255, 0.4);
    }

    &.active > a {
      color: @off-white;
    }

    &:not(.active) > a:hover {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .pms-inner {
    i {
      cursor: pointer;
      color: rgba(255, 255, 255, 0.4);
    }

    input[type="text"] {
      background: transparent;

      @media (min-width: @screen-sm-min) {
        border: 1px solid transparent;

        &:focus {
          border-color: @input-border;
        }
      }

      @media (max-width: @screen-xs-max) {
        border: 1px solid @input-border;
      }

      &:not(:focus) {
        cursor: pointer;
      }
    }
  }
}
.mCSB_scrollTools {
  .mCSB_draggerContainer {
    z-index: 10;
  }
}

.mCS-minimal-dark {
  &.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: #6b7175;
  }

  &.mCSB_scrollTools_onDrag .mCSB_dragger .mCSB_dragger_bar {
    background: #6b7175 !important;
  }
}
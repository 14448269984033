// dimensions
@timetable-hour-column-width: 96px;
@timetable-hour-row-height: 46px;
@timetable-heading-height: 30px;

// colors & decoration
@timetable-grid-color: @table-border-color;
@timetable-grid: 1px solid @timetable-grid-color;
@timetable-row-header-padding: 15px;
@timetable-row-header-color: @table-bg-accent;
@timetable-legend-row-separator: 1px solid @table-border-color;
@timetable-entry-row-separator: none;
@timetable-row-header-gap: 5px solid transparent;
@timetable-row-uneven-color: transparent;
@timetable-row-even-color: @table-bg-accent;
@timetable-entry-color: @brand-primary;
@timetable-entry-color-hover: darken(@timetable-entry-color, 10%);
@timetable-entry-border: 1px solid darken(@timetable-entry-color, 15%);
@timetable-entry-padding: 10px;

.valign-middle() {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.valign-parent() {
  transform-style: preserve-3d;
}

.ellipsis(@width: 100%) {
  display: inline-block;
  max-width: @width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.timetable {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;

  box-sizing: border-box;

  .clearfix();

  *, *:before, *:after {
    box-sizing: inherit;
  }

  ul, li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  aside, section {
    float: left;
  }

  aside {
    max-width: 30%;
    padding: 0 !important;
    margin-top: @timetable-hour-row-height;
    border-right: @timetable-row-header-gap;
    li {
      padding: 0 @timetable-row-header-padding;
      background-color: @timetable-row-header-color;
      line-height: @timetable-hour-row-height;

      &:not(:last-of-type) {
        border-bottom: @timetable-legend-row-separator;
      }

      .row-heading {
        .ellipsis();
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
  }

  aside li, time li {
    height: @timetable-hour-row-height;
  }

  section {
    flex-grow: 1;
    padding: 0 !important;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    time {
      white-space: nowrap;
      display: inline-block;
    }

    header {
      height: @timetable-hour-row-height;
      transform-style: preserve-3d;

      .clearfix();

      font-size: 0;

      li {
        display: inline-block;
        font-size: 1rem;
        overflow: visible;
        width: 0;
        line-height: @timetable-hour-row-height;
        display: inline-block;
        position: relative;

        &:not(:last-of-type) {
          width: @timetable-hour-column-width
        }

        .time-label {
          display: block;
          position: absolute;
          left: 0;
        }

        &:not(:first-of-type) .time-label {
          transform: translateX(-50%);
        }
        &:last-of-type .time-label {
          transform: translateX(-100%);
        }
      }
    }
  }

  ul.room-timeline {
    border-left: none;
    position: relative;
    overflow: hidden;

    li {
      position: relative;
      background-color: @timetable-row-even-color;
      &:nth-of-type(odd) {
        background-color: @timetable-row-uneven-color;
      }
      &:first-of-type {
        border-top: @timetable-grid;
      }
      &:last-of-type {
        border-bottom: @timetable-grid;
      }
      &:not(:last-of-type) {
        border-bottom: @timetable-entry-row-separator;
      }
      &:first-child .time-entry {
        height: @timetable-hour-row-height - 2px;
      }

      &:after, &:before {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }

      &:before {
        background-image: linear-gradient(to right, @timetable-grid-color 1px, transparent 1px);
        background-size: @timetable-hour-column-width / 4 auto;
      }
      &:after {
        background-image: linear-gradient(to right, @timetable-grid-color, @timetable-grid-color 1px, @timetable-row-even-color 1px, @timetable-row-even-color 2px, @timetable-grid-color 2px, @timetable-grid-color 3px, transparent 3px, transparent);
        background-size: @timetable-hour-column-width auto;
        background-position: -2px 0;
      }
    }
  }

  .time-entry {
    background-color: @timetable-entry-color;
    transition: 200ms background-color;
    height: @timetable-hour-row-height - 1px;
    display: block;
    position: absolute;
    z-index: 2;
    padding: 0 @timetable-entry-padding;
    white-space: normal;
    overflow: hidden;
    color: white;
    border: @timetable-entry-border;

    .valign-parent();

    small {
      .valign-middle();
      display: block;
    }

    &:hover {
      background-color: @timetable-entry-color-hover;
    }
  }
}
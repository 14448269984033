.tab-nav {
  box-shadow: inset 0 -2px 0 0 rgba(255, 255, 255, 0.1);

  li {
    & > a {
      color: rgba(255, 255, 255, 0.4);

      &:after {
        background: @off-white;
      }

      &:hover {
        color: rgba(255, 255, 255, 0.75);
      }
    }

    &.active {
      & > a {
        color: #fff;
      }
    }
  }
}
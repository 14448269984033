.form-control-alt {
  border-bottom: 1px solid #e0e0e0;

  &:focus {
    border-color: #d6d6d6;
  }

  color: #000;
}

.checkbox, .radio {
  input {
    &:checked + .input-helper {
      &:before {
        border-color: @off-white;
      }
    }
  }

  .input-helper {
    &:before {
      border: 2px solid rgba(255, 255, 255, 0.4);
    }
  }
}

.checkbox {
  input {
    &:checked + .input-helper {
      &:before {
        background-color: @off-white;
      }
    }
  }

  .input-helper {
    &:after {
      font-size: 13px;
      top: 1px;
      color: @card-bg;
    }
  }
}

.radio {
  .input-helper {
    &:after {
      background: @off-white;
    }
  }
}

.fg-line {
  .form-control {
    &:disabled {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  &:not([class*=has-]):after {
    background: @off-white;
  }
}

.fg-line-alt {
  &:not([class*=has-]):after {
    background: @m-blue;
  }
}

.fg-float {
  .fg-label {
    color: @off-white;
  }

  .fg-toggled .fg-label {
    opacity: 0.3;
  }
}

.toggle-switch {
  .ts-helper {
    background: rgba(255, 255, 255, 0.07);
  }
}
.bootstrap-datetimepicker-widget {
  table td {
    span {
      color: @off-white;
    }
  }

  a[data-action] {
    color: #1F292F !important;
  }
}

.timepicker-picker {
  table {
    tbody tr + tr:not(:last-child) {
      background: #1F292F;
    }
  }

  .btn,
  .btn:hover {
    color: @card-bg !important;
  }
}

.datepicker {
  table {
    thead {
      tr {
        &:first-child {
          th {
            background: #1F292F;
            &:hover {
              background: #1F292F;
            }
          }
        }

        &:last-child {
          &:not(:only-child) {
            background: darken(#1F292F, 3%);
          }
        }
      }
    }

    tbody {
      tr {
        &:last-child {
          td {
            padding-bottom: 25px;
          }
        }

        td {
          &:first-child {
            padding-left: 13px;
          }

          &:last-child {
            padding-right: 13px;
          }
        }
      }
    }

    td {
      &.active {
        &:before {
          background-color: #1F292F;
        }
      }
    }
  }
}

.datepicker-months .month,
.datepicker-years .year,
.timepicker-minutes .minute,
.timepicker-hours .hour {
  &.active {
    background: #1F292F;
  }
}
.card {
  background: @card-bg;

  .card-header {
    &.ch-alt {
      background-color: rgba(255, 255, 255, 0.02);
    }
  }

  a.ch-alt:hover {
    background-color: rgba(255, 255, 255, 0.055);
  }
}
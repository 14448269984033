.login-content,
.four-zero,
.public-page-content {
  background: @body-bg url('../img/hexbg_dark.png') center center;
  background-size: cover;
}

.fz-block {
  background: @card-bg;
}

.fzb-links {
  & > a {
    color: @text-color;
  }
}
.panel-collapse {
  margin-bottom: 10px !important;

  .panel-heading {
    &:before,
    &:after,
    .panel-title > a:before,
    .panel-title > a:after {
      .transition(all);
      .transition-duration(300ms);
      .backface-visibility(hidden);
      position: absolute;
    }

    .panel-title {
      & > a {
        color: rgba(255, 255, 255, 0.5);

        &:before {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }

    &:before,
    &:after {
      left: 3px;
      top: 3px;
      font-size: 18px;
    }

    &.active {
      .panel-title > a {
        color: #fff;

        &:before {
          background: rgba(255, 255, 255, 0.7);
        }
      }

      &:before {
        .scale(0);
      }
    }
  }
}

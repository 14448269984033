.block-header {
  & > h2 {
    text-transform: uppercase;
    & > small {
      color: @headings-small-color;
    }
  }
}

.actions {

  & > li > a,
  & > a {
    border-radius: 50%;
    margin: 0 2px;

    & > i {
      color: rgba(255, 255, 255, 0.5);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.07);

      & > i {
        color: @off-white
      }
    }
  }
}

.actions-alt {
  & > li {
    & > a {
      & > i {
        color: #adadad;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.07);

        & > i {
          color: #333;
        }
      }
    }
  }
}

.line-wrap {
  margin: 12px 17px;
  cursor: pointer;

  &, .line {
    .transition(all);
    .transition-duration(300ms);
  }
}

.load-more {
  a {
    background-color: @off-white;
    color: #333;

    i {
      margin-right: 3px;
    }

    &:hover {
      background-color: #fff;
    }
  }
}

.view-more {
  &:not(.view-more-light) {
    color: @text-muted;

    &:hover {
      color: @off-white;
    }
  }

  &.view-more-light {
    color: #828282;

    &:hover {
      color: #333;
    }
  }
}

.page-loader {
  background: #1E262B;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;

  .preloader {
    width: 50px;
    position: absolute;
    left: 50%;
    margin-left: -25px;
    top: 50%;
    margin-top: -55px;
    .animated(fadeIn, 3000ms);

    p {
      white-space: nowrap;
      position: relative;
      left: -9px;
      top: 22px;
      color: rgba(255, 255, 255, 0.3);
    }
  }
}

.action-header {
  background-color: rgba(255, 255, 255, 0.04);
}

.ahs-input {
  background: transparent;
  color: #fff;

  .placeholder(@off-white);
}

.ahs-close {
  background: @off-white;
  color: #333;

  &:hover {
    opacity: 0.7;
  }
}

.ma-backdrop-alt {
  position: absolute;
}
.bootstrap-select {
  & > .btn-default {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
    position: relative;
    color: @off-white;

    &:hover {
      color: @off-white;
    }
  }

  .bs-searchbox {
    background: #f7f7f7;

    &:before {
      top: 1px;
      color: #313C42;
    }

    input {
      color: @text-dark;
    }
  }
}

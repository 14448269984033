.chosen-container {
  .chosen-results {
    li {
      &.result-selected {
        color: #444;

        &:before {
          color: @card-bg;
        }
      }
    }
  }
}

.chosen-container-single {
  .chosen-single {
    color: @off-white;
    text-transform: none;
  }

  .chosen-search {
    background: #F7F7F7;

    &:before {
      color: @card-bg;
    }

    input[type=text] {
      color: @card-bg;
    }
  }
}

.chosen-container-multi {

  .chosen-choices {
    li {
      &.search-choice {
        background: rgba(266, 255, 255, 0.1);
        color: @off-white;
      }
    }
  }
}

.search-choice-close {
  &:before {
    color: @off-white;
    background: #454F55;
  }
}

select.chosen {
  display: none;
}
